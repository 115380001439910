<template>
  <div class="onlineTrainingHome">
    <ul>
      <li @click="go('/training/onlineTrainingHome/trainingDiagnosis', qx.uf_onlinetrainexam)">
        <img src="@/assets/img/conrea/contact_demo.png" alt="">
        <p>在线训练相关检查及诊断</p>
      </li>
      <li @click="go('/corneaContact/Iframe?type=12', qx.uf_onlinetrainprescription)">
        <img src="@/assets/img/conrea/contact_inquiry.png" alt="">
        <p>在线训练处方</p>
      </li>
      <li @click="go('/training/onlineTrainingHome/trainingCourse', qx.uf_onlinetrainmanagement)">
        <img src="@/assets/img/conrea/contact_check.png" alt="">
        <p>在线训练管理</p>
      </li>
      <li @click="go('/corneaContact/Iframe?type=10', qx.uf_onlinetraindes)">
        <img src="@/assets/img/conrea/contact_check.png" alt="">
        <p>在线训练方案介绍</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "onlineTrainingHome",
  data() {
    return {
      qx: {}
    }
  },
  created() {
    this.qx = this.$store.state.bases.qx



  },
  methods: {
    go(url, qx) {
      // console.log(qx)
      if (!qx) return this.$alert('请设置相关权限', '提示')
      this.$router.push({
        path: url
      })
    }
  }
}
</script>

<style scoped lang="scss">
.onlineTrainingHome {
  width: 100vw;
  height: 87vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
h3 {padding: .5rem 0;}
.pdd-2 {padding-top: 15vh}
ul {
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    padding: 0 .6rem;
    width: 11em;
    cursor: pointer;
    margin: 0 .6rem;
    img {
      display: block;
      width: 1.8rem;
      margin: 0 auto;
      margin-bottom: .2rem;
    }
  }
}
.pd-4 {padding-top: 4vh}
</style>
